import React from "react";

const Header = ({ header }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Gilroy-Bold",
        fontSize: "32px",
        paddingTop:'50px',
        marginBottom:'20px'
      }}
    >
      {header}
    </div>
  );
};

export default Header;
