// WhatsAppButton.js
import React from "react";
import "./WhatsAppButton.css"; // You'll need to create this CSS file for styling

const WhatsAppButton = () => {
    // Replace 'YOUR_WHATSAPP_NUMBER' with your actual WhatsApp number
    const whatsappNumber = "+96176880917";

    const openWhatsApp = () => {
        window.open(`https://wa.me/${whatsappNumber}`, "_blank");
    };

    return (
        <div className="whatsapp-button" onClick={openWhatsApp}>
            <i className="bi bi-whatsapp" style={{ fontSize: "30px" }}></i>
        </div>
    );
};

export default WhatsAppButton;
