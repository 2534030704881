import React from "react";
import { motion } from "framer-motion";

const SocialmediaLinks = () => {
  return (
    <div className="social-media-links d-flex justify-content-center my-5">
      <motion.div
        whileHover={{ scale: 1.1, zIndex: 1000 }}
        whileTap={{ scale: 0.9 }}
      >
        <a
          href="https://www.facebook.com/oliviaandaket"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2"
          style={{ color: "white" }}
        >
          <i className="bi bi-facebook" style={{ fontSize: "32px" }}></i>
        </a>
      </motion.div>
      <motion.div
        whileHover={{ scale: 1.1, zIndex: 1000 }}
        whileTap={{ scale: 0.9 }}
      >
        <a
          href="https://www.instagram.com/oliviaandaket/"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2"
          style={{ color: "white" }}
        >
          <i className="bi bi-instagram" style={{ fontSize: "32px" }}></i>
        </a>
      </motion.div>
        <motion.div
            whileHover={{ scale: 1.1, zIndex: 1000 }}
            whileTap={{ scale: 0.9 }}
        >
            <a
                href="https://www.linkedin.com/company/oliviaandaket/"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2"
                style={{ color: "white" }}
            >
                <i className="bi bi-linkedin" style={{ fontSize: "32px" }}></i>
            </a>
        </motion.div>
    </div>
  );
};

export default SocialmediaLinks;
