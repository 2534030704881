import React from "react";
import { Col, Row, Container } from "react-bootstrap";

const About = ({ aboutData }) => {
  return (
    <Container fluid style={{ backgroundColor: "#495E57", color: "white", minHeight:'40vh' }}>
      <h1
        style={{
          fontFamily: "Gilroy-bold",
          paddingTop: "40px",
          paddingBottom: "20px",
        }}
      >
        {aboutData.title}
      </h1>
      <Row>
        <Col  >
            <p style={{ fontFamily: "Gilroy-Light", paddingBottom: "40px", whiteSpace: 'pre-line' }}>
            {aboutData.description}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
