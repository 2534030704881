import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Form, Container, } from "react-bootstrap";
import { motion } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";

const validationSchema = yup.object({
  name: yup.string().required("Your name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Your email is required"),
  message: yup.string().required("Message is required"),
});

function ContactForm() {
  const [showAlert, setShowAlert] = useState(false);
  const [userName, setUserName] = useState("");
  const timerRef = React.useRef(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setUserName(values.name);
      setShowAlert(true);
      formik.resetForm();
      timerRef.current = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      const toSend = {
        to: "kamel.darazi@gmail.com",
        from: "Olivia",
        subject: "From Olivia Website Contact us",
        message: "<!-- Online HTML, CSS and JavaScript editor to run code online. --><!DOCTYPE html><html lang=\"en\"><head>  <meta charset=\"UTF-8\" />  <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\" />  <title>Olivia Contact us</title>  <style>    body {      margin: 20px;    }    .intro-container {      display: flex;      align-items: center;      margin-bottom: 20px;    }    .intro {      font-size: 18px;      font-weight: bold;      margin-left: 20px;    }    .info-box {      border-radius: 10px;      padding: 20px;      background-color: #f8f8f8;      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);    }    .info-label {      font-size: 16px;      font-weight: bold;    }    p {      margin-top: 0;    }  </style></head><body>  <div class=\"intro\">      <img src=\"https://creasoft.uk/olivia/olivia-logo.png\" alt=\"olivia Logo\" width=\"80\" height=\"80\">  </div>    <br>  <div class=\"intro-container\">    <div class=\"intro\">      <p>This email is sent from Olivia Website- Contact Us</p>    </div>  </div>  <div class=\"info-box\">    <div class=\"info-label\">From: " + values.name + "</div><br>     <div class=\"info-label\">Email: " + values.email + "</div><br>        <div class=\"info-label\">Message: " + values.message + "     </div></div></body></html>"
      }
      try {
        const response = await fetch('https://cslbauth.uk/users/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': process.env.REACT_APP_AUTH
          },
          body: JSON.stringify(toSend),
        });


        if (response.ok) {
          setUserName(values.name);
          setShowAlert(true);
          formik.resetForm();
          console.log('Form data', values);
          timerRef.current = setTimeout(() => {
            setShowAlert(false);
          }, 5000);
        } else {
          console.error(response);
          console.error('Failed to send email');
        }
      } catch (error) {
        console.error('Error sending email:', error);
      }

    },
  });

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);
  return (
    <Container fluid>
      {showAlert && (
        <div className="alert-container">
          <span>Thank you, {userName}! Your form has been submitted.</span>
          <button onClick={() => setShowAlert(false)}>X</button>
        </div>
      )}
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="name" style={{ fontFamily: "Gilroy-medium" }}>
            Name* :
          </Form.Label>
          <Form.Control
            type="text"
            name="name"
            id="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            isInvalid={formik.touched.name && formik.errors.name}
            style={{ borderColor: "#282828", fontFamily: "Gilroy-Light" }}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="email" style={{ fontFamily: "Gilroy-medium" }}>
            Email* :
          </Form.Label>
          <Form.Control
            type="email"
            name="email"
            id="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            isInvalid={formik.touched.email && formik.errors.email}
            style={{ borderColor: "#282828", fontFamily: "Gilroy-Light" }}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="message" style={{ fontFamily: "Gilroy-medium" }}>
            Message* :
          </Form.Label>
          <Form.Control
            as="textarea"
            id="message"
            name="message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
            isInvalid={formik.touched.message && formik.errors.message}
            style={{
              height: "140px",
              borderColor: "#282828",
              fontFamily: "Gilroy-Light",
            }}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <ReCAPTCHA sitekey='6LfJ7egoAAAAAP1yYYdFp9GjcHGtqlLWa77Y9cCM'
          onChange={(value)=>setRecaptchaValue(value)}/>
        </Form.Group>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <motion.button
              variant="secondary"
              type="submit"
              disabled={!recaptchaValue}
              style={{
                fontFamily: "Gilroy-Bold",
                backgroundColor: "#282828",
                borderRadius: "10px",
                color: "white",
                borderWidth: "0px",
                width: "100px",
                height: "35px",
              }}
              initial={{ opacity: 0.6 }}
              whileHover={{
              scale: 1.2,
            }}
              whileTap={{ scale: 0.9 }}
              whileInView={{ opacity: 1 }}
          >
            Submit
          </motion.button>
        </div>
      </Form>
    </Container>
  );
}

export default ContactForm;
