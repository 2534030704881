import "./App.css";
import { useState, useEffect } from "react";
import Navigationbar from "./components/NavigationBar/Navbar";
import HomePage from "./components/Pages/HomePage";
import axios from "axios";
import LoadingPage from "./components/LoadingPage";
import ReactGA from "react-ga4";
function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state
  ReactGA.initialize("G-QCYX56RC7P");
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.post(
            "https://creasoft.uk/olivia/getAllData.php",
            {all: true}
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCategory();
  }, []);

  if (loading) {
    return <LoadingPage/>; 
  }

  return (
    <>
      <Navigationbar data={data} />
      <HomePage data={data} />
    </>
  );
}

export default App;
