import React from 'react';
import { Spinner } from 'react-bootstrap';
import '../App.css';

function LoadingPage() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw'
    }}>
      <Spinner className="slow-spin" animation="border" role="status" style={{
        width: '150px',
        height: '150px',
        border: '10px solid currentColor',
        borderColor: 'currentColor transparent currentColor transparent',
        borderRadius: '50%',
        position: 'relative'
      }}>
        <img src="/Asset 2.png" alt="Website Logo" style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          height: '80%'
        }} />
      </Spinner>
    </div>
  );
}

export default LoadingPage;