import React, { useState, useCallback, useEffect } from "react";
import { Card as BootstrapCard, Carousel } from "react-bootstrap";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "react-bootstrap-icons";
import "./MyCarousel.css";

const MyCarousel = ({ featuredData }) => {
  const [selectedData, setSelectedData] = useState(null);

  const handleClose = useCallback(() => {
    setSelectedData(null);
  }, []);

  const handleButtonClick = useCallback((data) => {
    setSelectedData(data);
  }, []);

  useEffect(() => {
    const handleEscapePress = (event) => {
      if (event.key === "Escape" && selectedData) {
        handleClose();
      }
    };
    window.addEventListener("keydown", handleEscapePress);
    return () => window.removeEventListener("keydown", handleEscapePress);
  }, [selectedData, handleClose]);

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      backdropFilter: "blur(5px)",
      zIndex: 1500,
    },
    enlargedCard: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 2000,
      maxWidth: "700px",
      width: "98%",
    },
    carouselImage: {
      height: "50vh",
      objectFit: "cover",
    },
    closeButton: {
      position: "absolute",
      zIndex: 2000,
      right: 0,
      top: 0,
    },
  };

  return (
      <>
        <Carousel>
          {featuredData.map((data, index) => (
              <Carousel.Item key={index}>
                <img
                    className="d-block w-100"
                    src={data.cover}
                    alt={`Slide ${index}`}
                    style={{ height: "75vh", width: "100%", objectFit: "cover" }}
                    onError={(e) => {
                      if (e.target.src !== "/Asset 2.png") {
                        e.target.src = "/Asset 2.png";
                      }
                    }}
                />
                <Carousel.Caption
                    style={{
                      backgroundColor: "rgba(40, 40, 40, 0.80)",
                      borderRadius: "20px",
                    }}
                >
                  <div style={{ fontFamily: "Gilroy-Bold", fontSize: "30px" }}>
                    {data.name}
                  </div>
                  <p style={{ fontFamily: "Gilroy-Light" }}>{data.description}</p>
                  {data.images.length > 1 && (
                      <motion.button
                          onClick={() => handleButtonClick(data)}
                          variant="secondary"
                          style={{
                            fontFamily: "Gilroy-Medium",
                            backgroundColor: "#495E57",
                            borderRadius: "10px",
                            color: "white",
                            borderWidth: "0px",
                            width: "120px",
                            height: "45px",
                            marginBottom: "20px",
                          }}
                          initial={{ opacity: 0.6 }}
                          whileHover={{
                            scale: 1.2,
                          }}
                          whileTap={{ scale: 0.9 }}
                          whileInView={{ opacity: 1 }}
                      >
                        Learn more
                      </motion.button>
                  )}
                </Carousel.Caption>
              </Carousel.Item>
          ))}
        </Carousel>

        <AnimatePresence>
          {selectedData && (
              <>
                <div style={styles.overlay} onClick={handleClose}></div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={styles.enlargedCard}
                    onClick={(e) => e.stopPropagation()}
                >
                  <BootstrapCard style={styles.enlargedCard}>
                    <div style={styles.closeButton} onClick={handleClose}>
                      <X size={40} color="#495E57" />
                    </div>
                    <Carousel style={styles.carouselContainer}>
                      <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={selectedData.cover}
                            alt="Cover Image"
                            style={styles.carouselImage}
                            onError={(e) => {
                              if (e.target.src !== "/Asset 2.png") {
                                e.target.src = "/Asset 2.png";
                              }
                            }}
                        />
                      </Carousel.Item>
                      {selectedData.images.map((image, index) => (
                          <Carousel.Item key={index}>
                            <img
                                className="d-block w-100"
                                src={image.name}
                                alt={`Slide ${index + 1}`}
                                style={styles.carouselImage}
                            />
                          </Carousel.Item>
                      ))}
                    </Carousel>{" "}
                    <BootstrapCard.Body>
                      <BootstrapCard.Title style={{ fontFamily: "Gilroy-Bold" }}>
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                        >
                          <span>{selectedData.name}</span>
                        </div>
                      </BootstrapCard.Title>
                      <BootstrapCard.Text style={{ fontFamily: "Gilroy-Light" }}>
                        {selectedData.description}
                      </BootstrapCard.Text>
                    </BootstrapCard.Body>
                  </BootstrapCard>
                </motion.div>
              </>
          )}
        </AnimatePresence>
      </>
  );
};

export default MyCarousel;
