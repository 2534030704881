import React, { useEffect } from "react";

const NavbarItems = ({ closeOffcanvas }) => {
  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.querySelector("#about");
      const productsSection = document.querySelector("#products");
      const contactSection = document.querySelector("#contact");
      const featuredSection = document.querySelector("#featured");

      const featuredLink = document.querySelector('a[href="#featured"]');
      const aboutLink = document.querySelector('a[href="#about"]');
      const productsLink = document.querySelector('a[href="#products"]');
      const contactLink = document.querySelector('a[href="#contact"]');

      const checkActive = (section, link) => {
        const rect = section.getBoundingClientRect();
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          link.classList.add("underline");
        } else {
          link.classList.remove("underline");
        }
      };

      checkActive(aboutSection, aboutLink);
      checkActive(productsSection, productsLink);
      checkActive(contactSection, contactLink);
      checkActive(featuredSection, featuredLink);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav>
      <ul>
        <li>
          <a href="#featured" onClick={closeOffcanvas}>
            Featured Products
          </a>
        </li>
        <li>
          <a href="#about" onClick={closeOffcanvas}>
            About us
          </a>
        </li>
        <li>
          <a href="#products" onClick={closeOffcanvas}>
            Products
          </a>
        </li>
        <li>
          <a href="#contact" onClick={closeOffcanvas}>
            Contact us
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default NavbarItems;
