// Footer.js
import React from "react";
import "./Pages/Footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <p>
                &copy; {new Date().getFullYear()} Olivia - Andaket. All rights reserved.
            </p>
            <p className="poweredBy">
                Powered By <a href="https://creasoftlb.com" target="_blank" rel="noopener noreferrer">Creasoft Lebanon</a>
            </p>
        </footer>
    );
};

export default Footer;