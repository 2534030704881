import React, { useState, useCallback, useEffect } from "react";
import { Card as BootstrapCard,  Carousel } from "react-bootstrap";
import { motion, AnimatePresence } from "framer-motion";
import Header from "./Header";
import {X} from "react-bootstrap-icons";
import ReactGA from "react-ga4";
const CardComponent = ({ productsData }) => {
  ReactGA.initialize("G-QCYX56RC7P");

  const [selectedCard, setSelectedCard] = useState(null);

  const handleClose = useCallback(() => {
    setSelectedCard(null);
  }, []);

  const handleCardClick = useCallback((item) => {
    ReactGA.event({
      category: item.name, // Use item instead of selectedCard
      action: "Opened Full",
      label: item.name, // optional
      value: item.id, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    setSelectedCard(item);
  }, []);

  useEffect(() => {
    const handleEscapePress = (event) => {
      if (event.key === "Escape" && selectedCard) {
        handleClose();
      }
    };
    window.addEventListener("keydown", handleEscapePress);
    return () => window.removeEventListener("keydown", handleEscapePress);
  }, [selectedCard, handleClose]);

  // Styles
  const styles = {
    cardsContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "20px",
      paddingBottom: "40px",
      margin: "20px",
      filter: selectedCard ? "blur(5px)" : "none",
      pointerEvents: selectedCard ? "none" : "auto",
    },
    card: {
      maxWidth: "300px",
      width:"300px",
      height: "300px", // Set the desired height here
      borderRadius: "10px",
      borderWidth: "0px",
    },
    img: {
      height: "200px",
      maxWidth: "100%",
      borderRadius: "10px",
      cursor: "pointer",
    },
    title: {
      fontFamily: "Gilroy-Bold",
    },
    text: {
      fontFamily: "Gilroy-Light",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent black
      backdropFilter: "blur(5px)", // blur effect
      zIndex: 1500, // ensure it's below the enlargedCard but above everything else
    },

    enlargedCard: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 2000,
      maxWidth: "700px",
      width: "98%",
    },

    enlargedCardBody: {
      width: "100%",
    },
    closeButton: {
      position: "absolute",
      zIndex: 2000,
      right: 0,
      top: 0,
    },
    carouselContainer: {
      height: "auto",
    },
    carouselImage: {
      height: "50vh",
      objectFit: "cover",
    },
  };

  return (
      <>
        {productsData &&
            productsData.map((category) => (
                // Add a condition to check if category.products is not empty
                // before rendering the category
                category!=null && category.products!=null && category.products.length > 0 && (
                      <div key={category.id}>
                        <div style={{color: "white"}}>
                          <Header header={category.name}/>
                        </div>
                        <div style={styles.cardsContainer}>
                          {category.products.map((product) => (

                              <motion.div
                                  key={product.id}
                                  layoutId={product.id.toString()}
                                  whileHover={{scale: 1.1, zIndex: 1000}}
                                  whileTap={{scale: 0.9}}
                                  onClick={() => handleCardClick(product)}
                              >
                                <BootstrapCard style={styles.card}>
                                  <BootstrapCard.Img
                                      variant="top"
                                      src={product.cover}
                                      style={styles.img}
                                      onError={(e) => {
                                        if (e.target.src !== "/Asset 2.png") {
                                          e.target.src = "/Asset 2.png";
                                        }
                                      }}
                                  />
                                  <BootstrapCard.Body>
                                    <BootstrapCard.Title
                                        style={{
                                          ...styles.title,
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                    >
                                      <span>{product.name}</span>
                                    </BootstrapCard.Title>
                                    <BootstrapCard.Text style={styles.text}>
                                      {product.description}
                                    </BootstrapCard.Text>
                                  </BootstrapCard.Body>
                                </BootstrapCard>
                              </motion.div>
                          ))}
                        </div>
                      </div>
                  )
            )) }
        <AnimatePresence>
          {selectedCard && (
              <>
                <div style={styles.overlay} onClick={handleClose}></div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={styles.enlargedCard}
                    onClick={(e) => e.stopPropagation}
                >
                  <BootstrapCard style={styles.enlargedCard}>
                    <div style={styles.closeButton} onClick={handleClose}>
                      <X size={40} color="#495E57" />
                    </div>
                    <Carousel style={styles.carouselContainer} indicators={selectedCard.images.length > 0} controls={selectedCard.images.length > 0}>

                      <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={selectedCard.cover}
                            alt="Cover Image"
                            style={styles.carouselImage}
                            onError={(e) => {
                              if (e.target.src !== "/Asset 2.png") {
                                e.target.src = "/Asset 2.png";
                              }
                            }}
                        />
                      </Carousel.Item>
                      {/* Render the remaining images */}
                      {selectedCard.images.map((image, index) => (
                          <Carousel.Item key={index}>
                            <img
                                className="d-block w-100"
                                src={image.name}
                                alt={`Slide ${index + 1}`}
                                style={styles.carouselImage}
                            />
                          </Carousel.Item>
                      ))}
                    </Carousel>
                    <BootstrapCard.Body style={styles.enlargedCardBody}>
                      <BootstrapCard.Title
                          style={{
                            ...styles.title,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                      >
                        <span>{selectedCard.name}</span>
                      </BootstrapCard.Title>
                      <BootstrapCard.Text style={styles.text}>
                        {selectedCard.description}
                      </BootstrapCard.Text>
                    </BootstrapCard.Body>
                  </BootstrapCard>
                </motion.div>
              </>
          )}
        </AnimatePresence>
      </>
  );

};

export default CardComponent;
