import React from "react";
import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import NavbarItems from "./NavbarItems";
import SocialMediaLinks from "../SocialMediaLinks";
const OffcanvasButton = ({ name, ...props }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {/* Bar Icon */}
      <i
        className="fa fa-bars"
        onClick={handleShow}
        style={{ cursor: "pointer", fontSize: "24px", color: "white" ,marginLeft:"25px"}}
      ></i>

      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        style={{ background: "#282828", maxWidth: "80vw" }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            <img
              src="./Olivia_white.png"
              alt="Logo"
              style={{ maxWidth: "6rem" }}
            />
          </Offcanvas.Title>
          {/* Custom close button */}
          <i
            className="fa fa-times"
            onClick={handleClose}
            style={{
              cursor: "pointer",
              fontSize: "24px",
              color: "white",
              marginLeft: "auto",
            }}
          ></i>
        </Offcanvas.Header>
        <Offcanvas.Body
          style={{
            display: "grid",
            alignContent: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <NavbarItems closeOffcanvas={handleClose} />
        </Offcanvas.Body>
        <SocialMediaLinks />
      </Offcanvas>
    </>
  );
};

export default OffcanvasButton;
