import React, { useState, useEffect } from "react";
import "./HomePage.css";
import Header from "../Header";
import ContactForm from "../ContactForm";
import MyCarousel from "../Carousel/MyCarousel";
import GoogleMaps from "../GoogleMaps";
import { Container } from "react-bootstrap";
import Footer from "../Footer";
import About from "../About";
import CardComponent from "../CardComponent";
import WhatsAppButton from "../WhatsappButton";

function HomePage({data}) {
  return (
    <div className="homepage-container">
      <section id="featured">
       {data&& <MyCarousel featuredData={data.data.featured} />} 
      </section>
      <section id="about">
        {data && <About aboutData={data.data.about.about} />}
      </section>
      <section id="products" style={{ backgroundColor: "#282828" }}>
        {data && <CardComponent productsData={data.data.categories} />}
      </section>
      <section id="contact">
        <Header header="Contact us" />
        <Container style={{ minHeight: "60vh", maxWidth: "700px" }}>
          <ContactForm />
          <GoogleMaps />
        </Container>
      </section>
      <Footer />
        <WhatsAppButton />
    </div>
  );
}

export default HomePage;
