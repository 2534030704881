import React from "react";

const GoogleMaps = () => {
  const containerStyle = {
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',
    height: 0,
    borderRadius: '20px',  
    overflow: 'hidden',    
    marginTop: '30px'      
  };

  const iframeStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0,
  };

  return (
    <div style={containerStyle}>
      
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d52549.63344934078!2d36.27268898773736!3d34.59525724007777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDM2JzE1LjIiTiAzNsKwMTgnMTYuOSJF!5e0!3m2!1sen!2slb!4v1696778242811!5m2!1sen!2slb"
        style={iframeStyle}
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMaps;
