import React from "react";
import { Navbar } from "react-bootstrap";
import NavbarItems from "./NavbarItems";
import SocialMediaLinks from "../SocialMediaLinks";
import "./Navbar.css";
import "font-awesome/css/font-awesome.min.css";
import OffcanvasButton from "./OffcanvasButton";

function Navigationbar() {
  return (
      <div className="sidebar">
        {/* Navbar for smaller screens */}
        <Navbar className="d-md-none justify-content-center align-items-center sticky-top">
          <div>
            <OffcanvasButton name={"Burgericon"} />
          </div>
          {/* Logo */}
          <Navbar.Brand className="mx-auto"></Navbar.Brand>
          {/* Social Media Links */}
          <div style={{ marginLeft: "30px" }}>
            <img
                src="./Olivia_white.png"
                alt="Logo"
                style={{ maxWidth: "6rem", cursor: 'pointer' }}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            />
          </div>
        </Navbar>
        {/* Content for larger screens */}
        <div
            className="d-none d-md-flex flex-column h-100"
            style={{ justifyContent: "space-between" }}
        >
          {/* Logo */}
          <div className="align-self-center">
            <img
                src="./Olivia_white.png"
                alt="Logo"
                style={{ maxWidth: "10rem", cursor: 'pointer' }}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            />
          </div>
          {/* Navbar items */}
          <div className="align-self-center">
            <NavbarItems />
          </div>
          {/* Social Media Links at the bottom */}
          <div className="align-self-center">
            <SocialMediaLinks />
          </div>
        </div>
      </div>
  );
}

export default Navigationbar;
